///src/ui/handleOpen.js
import { toolbarOpenTrigger } from '../config/config';
import showToolbar from './showToolbar';

export default () => {
  var triggers = document.querySelectorAll(toolbarOpenTrigger), i;

  for (i = 0; i < triggers.length; ++i) {
    triggers[i].addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      showToolbar();
    });
  }
};

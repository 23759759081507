///src/user/previousAdConsent.js
import { analyticsCookie } from '../config/config';
import isEUUser from './isEUUser';
import readCookieAsBoolean from '../utils/readCookieAsBoolean';

// checks for whether or not consent for analytics has given
export default () => {
  // NON EU - check if there are any preferenes set then default to true if non EU and false if EU
  if( readCookieAsBoolean(analyticsCookie) == null ) {
    if( isEUUser() === false ) {
      return true;
    } else {
      return false;
    }
  } else {
    return readCookieAsBoolean(analyticsCookie);
  }
};
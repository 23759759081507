///src/ui/toolbarAfterPrefs.js
import { toolbarOverlay, toolbarPrefsClassname } from '../config/config';
import hasDefinedPrefs from '../user/hasDefinedPrefs';
import addClass from './addClass';
import removeClass from './removeClass';

export default () => {
  let el = document.querySelector(toolbarOverlay);
  if (el) {
    if(hasDefinedPrefs() === true) {
      addClass(el, toolbarPrefsClassname);
    } else {
      removeClass(el, toolbarPrefsClassname);
    }
  }
};
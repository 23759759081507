///src/utils/createCookie.js
export default (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    expires = '; expires='+date.toGMTString();
  }

  var domain = '; domain=.' + 
                location.hostname.split('.').reverse()[1] + '.' + 
                location.hostname.split('.').reverse()[0];
                
  document.cookie = name+'='+value+domain+expires+'; path=/';
};
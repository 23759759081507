///src/utils/loadScriptSrc.js
// https://stackoverflow.com/a/7719185
export default (src) => {
  return new Promise(function (resolve, reject) {
    var s;
    s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
};

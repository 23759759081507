///src/ui/formHandler.js
import { apiUrl, toolbarOverlay, userIDCookie } from '../config/config';
import eraseCookie from '../utils/eraseCookie';
import hideToolbar from './hideToolbar';
import readCookie from '../utils/readCookie';

export default () => {
  var el = document.querySelector(toolbarOverlay);

  if(el) {
    el.addEventListener('click', function(e) {
      if (e.target.matches('#taunton-user-consent__delete-all')) {
        var cookies = document.cookie.split(';'),
          userID = readCookie(userIDCookie);
        e.stopPropagation();
        e.preventDefault();

        // erase all cookies
        for (var i = 0; i < cookies.length; i++) {
          eraseCookie(cookies[i].split('=')[0]);
        }

        var url = apiUrl,
          request = {
            domain: window.location.hostname,
            data: {
              ad: 'false',
              at: 'false',
              deletion: 'true' // this indicates if it's a user click on delete all cookies
            }
          };

        if(userID) {
          request.data.uid = userID;
        }

        fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(request), // data can be `string` or {object}!
          headers: new Headers({
            'Content-Type': 'application/json'
          })
        }).then(function(res) {
          return res.json();
        }).catch(function(error) {
          if( window.console && typeof window.console.error === 'function' ) {
            console.error('Error:', error);
          }
          return;
        }).then(function(response) {
          hideToolbar();

          if( window.console && typeof window.console.log === 'function' ) {
            console.log('Success:', response);
          }

          try {
            var page_url = window.location.href.split('?'),
              qs = window.location.search;

            var result = window.location.href;

            if(qs.length) {
              var query_sub = qs.split('?');
              if( query_sub.length === 2) {
                var query_parts = query_sub[1].split('&');
                if( query_parts.indexOf('taunton_user_consent=delete-all') === -1 ) {
                  query_parts.push('taunton_user_consent=delete-all');
                }
                qs = '?' + query_parts.join('&');
              }
            } else {
              qs = '?taunton_user_consent=delete-all';
            }

            result = page_url[0] + qs;
            window.location.href = result;

          } catch(e) {
            if( window.console && typeof window.console.error === 'function' ) {
              console.error('Error:', e);
            }
          }
        });

      }
    });

  }
};
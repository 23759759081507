///src/main.js
// Global polyfills used throughout project
//import './polyfill/promise';
//import 'whatwg-fetch';
//import './polyfill/matches';
// end polyfills

// events
import onReady from './events/onReady';
import revokeConsent from './events/revokeConsent';

// user
import hasDefinedPrefs from './user/hasDefinedPrefs';
import hasExpiredPrefs from './user/hasExpiredPrefs';
import hasAdConsent from './user/hasAdvertisingConsent';
import hasAlConsent from './user/hasAnalyticsConsent';
import isEUUser from './user/isEUUser';
import analytics from './user/consent/analytics';
import advertising from './user/consent/advertising';
import {addConsentListener, consentState } from './user/consent/callbackListener';

// ui
import formHandler from './ui/formHandler';
import handleClose from './ui/handleClose';
import handleDelete from './ui/handleDelete';
import handleOpen from './ui/handleOpen';
import insertToolbar from './ui/insertToolbar';
import showToolbar from './ui/showToolbar';

// Utils
import loadScriptSrc from './utils/loadScriptSrc';
import readCookieAsBoolean from './utils/readCookieAsBoolean';

onReady(insertToolbar);
onReady(handleClose);
onReady(handleOpen);
onReady(formHandler);
onReady(handleDelete);
if(hasDefinedPrefs() === false) {
  try {
    var qs = window.location.search;
    // quick and dirty
    if(qs.indexOf('taunton_user_consent=delete-all') === -1) {
      onReady(showToolbar);
    }
  } catch(e) {
    onReady(showToolbar);
  }
} else if (hasExpiredPrefs()) {// NON EU - open by default if optin choice was made and has expired
  onReady(showToolbar);
}

let analyticsConsent = analytics();
let advertisingConsent = advertising();

// selectively expose things to the global namespace
export default {
  data: {
    ga_ua_id: undefined
  },
  events: {
    revokeConsent: revokeConsent,
    updateConsentListener: addConsentListener
  },
  user: {
    consent: {
      advertising: advertisingConsent,
      analytics: analyticsConsent
    },
    hasDefinedPrefs: hasDefinedPrefs,
    isEUUser: isEUUser,
    previousAdConsent: hasAdConsent,
    hasAnalyticsConsent: hasAlConsent,
    currentConsent: consentState
  },
  utils: {
    loadScriptSrc: loadScriptSrc,
    readCookieAsBoolean: readCookieAsBoolean
  }
};
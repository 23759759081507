///src/user/hasDefinedPrefs.js
import { preferencesCookie } from '../config/config';
import readCookie from '../utils/readCookie';

export default () => {
  if( readCookie(preferencesCookie) ) {
    return true;
  } else {
    return false;
  }
};
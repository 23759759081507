///src/user/consent/analytics.js
import { analyticsCheckbox, toolbarForm } from '../../config/config';
import hasAlConsent from '../hasAnalyticsConsent';
import onReady from '../../events/onReady';

export default() => {
  return new Promise((resolve) => {
    if (hasAlConsent() === true) {//check the cookie
      resolve(true);
    } else { //set the event on the form
      onReady(() => {
        var form = document.querySelector(toolbarForm),
        analytics = document.querySelector(analyticsCheckbox);

        if(form) {
          form.addEventListener('submit', function(e) {
            e.preventDefault();
            if(analytics && analytics.checked) {
              resolve(true);
            }
          });
        }
      });
    }
  });
};
import readCookie from './readCookie';

export default (name) => {
  var cookie = readCookie(name);

  if(cookie) {
    if(cookie === 'true' || cookie === '1') {
      return true;
    }

    if(cookie === 'false' || cookie === '0') {
      return false;
    }

    return cookie;
  }
  return null;
};
// checks for whether or not consent for ads has given at the time of page load
// Array of callbacks to be executed when consent changes
import { consentListeners } from '../../config/config';
import hasAdConsent from '../hasAdvertisingConsent';
import hasAlConsent from '../hasAnalyticsConsent';

function addConsentListener(callback) {
  consentListeners.push(callback);
}

function changeConsentCallback (consent) {
  consentListeners.forEach((callback) => {
    callback(consent);
  });
}

var consentState = {
  adconsent: hasAdConsent(),
  analyticsConsent: hasAlConsent()
};

export { addConsentListener, changeConsentCallback, consentState};
///src/user/hasExpiredPrefs.js
import { userIDCookie } from '../config/config';
import hasDefinedPrefs from './hasDefinedPrefs';
import readCookie from '../utils/readCookie';

export default () => {
  if(!hasDefinedPrefs() && (readCookie(userIDCookie) != null)) {
    return true;
  } else {
    return false;
  }
};
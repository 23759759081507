///src/ui/formHandler.js
import { advertisingCheckbox, advertisingCookie, analyticsCheckbox, analyticsCookie, apiUrl, preferencesCookie, toolbarForm, userIDCookie } from '../config/config';
import createCookie from '../utils/createCookie';
import hideToolbar from './hideToolbar';
import readCookie from '../utils/readCookie';
import { changeConsentCallback, consentState } from '../user/consent/callbackListener';

/**
 * ```
 * {
 *   "domain": "www.finecooking.com",
 *   "data": {
 *     "uid": "d15493f7-995f-43b5-b075-04b1e76b23fe",
 *     "ad": "test2",
 *     "at": "test",
 *     "deletion": "1"
 *   }
 * }
 * ```

 */
export default () => {
  var form = document.querySelector(toolbarForm),
    analytics = document.querySelector(analyticsCheckbox),
    advertising = document.querySelector(advertisingCheckbox);

  if(form) {
    form.addEventListener('submit', function(e){
      e.preventDefault();

      var url = apiUrl,
        request = {
          domain: window.location.hostname,
          data: {
            deletion: 'false' // this indicates if it's a user click on delete all cookies
          }
        },
        userID = readCookie(userIDCookie);

      if(analytics) {
        request.data.at = analytics.checked + '';
      }

      if(advertising) {
        request.data.ad = advertising.checked + '';
      }

      // :TODO: send GUID if it exists
      if(userID) {
        request.data.uid = userID;
      }

      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(request), // data can be `string` or {object}!
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      }).then(function(res) {
        return res.json();
      }).catch(function(error) {
        if( window.console && typeof window.console.error === 'function' ) {
          console.error('Error:', error);
        }
        return;
      }).then(function(response) {

        hideToolbar();
        createCookie(preferencesCookie, 'true', 365);

        //window.dataLayer = window.dataLayer || [];

        if(typeof request.data.at !== 'undefined') {
          createCookie(analyticsCookie, request.data.at + '', 365);
          consentState.analyticsConsent = request.data.at + '' === 'true' ? true : false;
          //window.dataLayer.push({'event': 'gdpr_analytics_consent'});
        }

        if(typeof request.data.ad !== 'undefined') {
          createCookie(advertisingCookie, request.data.ad + '', 365);
          consentState.adConsent = request.data.ad + '' === 'true' ? true: false;
          //window.dataLayer.push({'event': 'gdpr_advertising_consent'});
        }
    
        // pass new consent status to any callback method that has been requested.
        changeConsentCallback(consentState);

        // :TODO: test this when response from API is ready
        if(typeof request.data.uid === 'undefined' && typeof response !== 'undefined' && response.uid) {
          createCookie(userIDCookie, response.uid, 1825); // 5 years
        }

        if( window.console && typeof window.console.log === 'function' ) {
          console.log('Success:', response);
        }
      });

    });
  }
};
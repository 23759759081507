///src/config/config.js

// :TODO: update URL
exports.advertisingCheckbox = '#taunton-user-consent-advertising';
exports.advertisingCookie = 'taunton_user_consent_advertising';
exports.analyticsCheckbox = '#taunton-user-consent-analytics';
exports.analyticsCookie = 'taunton_user_consent_analytics';
exports.apiUrl = 'https://cjgd5n7n2k.execute-api.us-east-1.amazonaws.com/prod/gdpr-logger';
exports.preferencesCookie = 'taunton_user_consent_submitted';
exports.toolbarForm = '#taunton-user-consent__toolbar__form';
exports.toolbarOpenTrigger = '.taunton-user-consent__open';
exports.toolbarOpenClassname = 'taunton-user-consent-visible';
exports.toolbarPrefsClassname = 'taunton-user-consent--has-defined-prefs';
exports.toolbarOverlay = '#taunton-user-consent__overlay';
exports.toolbarButtonClose = '#taunton-user-consent__button-close';
exports.userIDCookie = 'taunton_user_consent_user_id';
exports.consentListeners = [];
///src/user/previousAdConsent.js
import { advertisingCookie } from '../config/config';
import isEUUser from './isEUUser';
import readCookieAsBoolean from '../utils/readCookieAsBoolean';

// checks for whether or not consent for ads has given at the time of page load
export default () => {
  // NON EU - check if there are any preferenes set then default to true if non EU and false if EU
  if( readCookieAsBoolean(advertisingCookie) == null ) {
    if( isEUUser() === false ) {
      return true;
    } else {
      return false;
    }
  } else {
    return readCookieAsBoolean(advertisingCookie);
  }
};
///src/user/consent/advertising.js
import { advertisingCheckbox, toolbarForm } from '../../config/config';
import hasAdConsent from '../hasAdvertisingConsent';
import onReady from '../../events/onReady';

export default() => {
  return new Promise((resolve) => {
    if (hasAdConsent() === true) {
      resolve(true);
    } else {
     onReady(() => {
       var form = document.querySelector(toolbarForm),
       advertising = document.querySelector(advertisingCheckbox);
 
       if(form) {
         form.addEventListener('submit', function(e) {
           e.preventDefault();
           if(advertising && advertising.checked) {
            resolve(true);
           }
         });
       }
     });
    }
  });
};
///src/ui/hideToolbar.js
import { toolbarOpenClassname, toolbarOverlay } from '../config/config';
import removeClass from './removeClass';

export default () => {
  var el = document.querySelector(toolbarOverlay),
    className = toolbarOpenClassname;

  if(el) {
    removeClass(el, className);
    el.setAttribute('aria-hidden', 'true');
  }

};
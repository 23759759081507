///src/ui/showToolbar.js
import { advertisingCheckbox, analyticsCheckbox, toolbarOpenClassname, toolbarOverlay } from '../config/config';
import addClass from './addClass';
import hasAdConsent from '../user/hasAdvertisingConsent';
import hasAlConsent from '../user/hasAnalyticsConsent';
import toolbarAfterPrefs from './toolbarAfterPrefs';

export default () => {
  var el = document.querySelector(toolbarOverlay),
    className = toolbarOpenClassname;

  if(el) {
    addClass(el, className);
    el.removeAttribute('aria-hidden');
    el.focus();

    if(hasAdConsent() === true) {
      checkTheBox(advertisingCheckbox);
    }

    if(hasAlConsent() === true) {
      checkTheBox(analyticsCheckbox);
    }

    toolbarAfterPrefs();

  }

  function checkTheBox(theCheckbox) {
    let el = document.querySelector(theCheckbox);
      if(el) {
        el.checked = true;
      }
  }

};
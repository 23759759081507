///src/ui/handleClose.js
import {toolbarButtonClose, toolbarOverlay} from '../config/config';
import hideToolbar from './hideToolbar';

export default () => {
  const el = document.querySelector(toolbarOverlay);
  const closeButton = document.querySelector(toolbarButtonClose);

  if(el) {
    el.addEventListener('click', function(e) {
      console.log(e.target);
      if (e.target.matches(toolbarOverlay)) {
        e.stopPropagation();
        hideToolbar();
      } else if(closeButton && e.target.closest(toolbarButtonClose)) {
        e.stopPropagation();
        hideToolbar();
      }
    });
  }
};